import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Brand_Icon from '../../Assets/Images/setting.png'
import Brand_Icon_2 from '../../Assets/Images/setting2.png'

const Navigation = () => {

    const [mobile, setMobileNav] = useState(false)
    const mobileNav = () => {
        setMobileNav(true)
    }
    const closeNav = () => {
        setMobileNav(false)
    }

    const [sticky, setSticky] = useState(false);

    useEffect(()=>{
        window.addEventListener('scroll', ()=>{
            window.scrollY > 50 ? setSticky(true) : setSticky(false)
        })
    },[])

  return (
    <div className={`${sticky ? 'md:bg-blue-900' : 'md:bg-transparent'} transform transition-transform duration-300 ease-in-out w-full bg-white relative md:fixed top-0 left-0 z-10`}>
        <div className='mx-auto max-w-6xl px-2 md:px-4 xl:px-8 py-6 flex items-center justify-between'>
            <div className='text-xl font-black text-blue-900 md:text-white flex items-center gap-1'><img src={Brand_Icon} alt='Brand Icon' className='w-6 hidden md:block'/><img src={Brand_Icon_2} alt='Brand Icon' className='w-6 md:hidden block'/> Brainbitsolutions</div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={mobile ? "rotate-90 size-6":"size-6 text-blue-900 md:hidden transform transition-transform duration-300 rotate-0"} onClick={mobileNav}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
            </svg>

            <ul className={mobile ? 'block absolute w-3/4 bg-blue-900 right-0 top-0 bottom-0 z-10 min-h-screen opacity-90' : 'hidden md:flex gap-2 items-center transition-transform duration-300 ease-in-out transform'}>
            <div className='block md:hidden flex items-center justify-between p-2'>
                <div className='text-xl font-black text-white flex items-center gap-1'><img src={Brand_Icon} alt='Brand Icon' className='w-6'/>Brainbitsolutions</div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-white" onClick={closeNav}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
            </div>
            <li className={mobile ? 'p-2':'px-3 text-sm'}>
            <NavLink
                to="/"
                className={({ isActive }) =>
                isActive ? 'text-white font-bold' : 'text-gray-100'
                }
            >
                Home
            </NavLink>
            </li>
            <li className={mobile ? 'p-2':'px-3 text-sm'}>
            <NavLink
                to="/about"
                className={({ isActive }) =>
                isActive ? 'text-white font-bold' : 'text-gray-100 transition-all delay-150 hover:font-bold duration-300'
                }
            >
                About Us
            </NavLink>
            </li>
            <li className={mobile ? 'p-2':'px-3 text-sm'}>
            <NavLink
                to="/solutions"
                className={({ isActive }) =>
                isActive ? 'text-white font-bold' : 'text-gray-100 transition-all delay-150 hover:font-bold duration-300'
                }
            >
                Services
            </NavLink>
            </li>
            <li className={mobile ? 'p-2':'px-3 text-sm'}>
            <NavLink
                to="/contact"
                className={({ isActive }) =>
                isActive ? 'text-white font-bold' : 'text-gray-100 transition-all delay-150 hover:font-bold duration-300'
                }
            >
                Contact
            </NavLink>
            </li>
            </ul>
        </div>
    </div>
  )
}

export default Navigation