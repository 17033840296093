import React from 'react'
import OpenImg from '../Assets/Images/layer-img.jpg'
import Logo from '../Assets/Images/setting.png'

const OpenSection = () => {
  return (
    <div className='w-full relative'>
        <img src={OpenImg} alt="" className='w-full'/>
        <div className='bg-black/50 absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center gap-1'>
            <div className='flex gap-1 text-2xl font-black text-white'><img src={Logo} alt="" className='w-8' /> Brainbit Solutions </div> 
            <h4 className='text-white text-center md:text-3xl'>Innovative IT solutions that works!</h4>  
        </div>
    </div>
  )
}

export default OpenSection