import React from 'react'
import Brand_Icon from '../../Assets/Images/setting2.png'
import './Footer.css'
import {NavLink} from 'react-router-dom'

const Footer = () => {
    return (
        <div className='footerContainer w-full bg-gray-100'>
            <div className='max-w-6xl px-2 md:px-4 xl:px-8 mx-auto'>
                <div className='md:flex'>
                    <div className='basis-1/4 mb-3'>
                        <div className='flex flex-col gap-1'>
                            <div
                                className='flex gap-1 text-blue-900 font-bold text-2xl md:text-sm lg:text-xl items-center'>
                                <img src={Brand_Icon} alt='Brand Icon' className='w-8'/>Brainbitsolutions
                            </div>
                        </div>
                    </div>
                    <div className='basis-1/4 mb-3 md:mb-0'>
                        <h4 className='text-blue-900 font-bold mb-4'>Services</h4>
                        <ul>
                            <li>
                                <NavLink to='#App-development'>App Development</NavLink>
                            </li>
                            <li>
                                <NavLink to='#App-development'>Web Development</NavLink>
                            </li>
                            <li>
                                <NavLink to='#App-development'>IT Sales</NavLink>
                            </li>
                            <li>
                                <NavLink to='#App-development'>Business Strategy</NavLink>
                            </li>

                        </ul>
                    </div>
                    <div className='basis-1/4 mb-3 md:mb-0'>
                        <h4 className='text-blue-900 font-bold mb-4'>Quick Links</h4>
                        <ul>
                            <li>
                                <NavLink to='#App-development'>About Us</NavLink>
                            </li>
                            <li>
                                <NavLink to='#App-development'>Services</NavLink>
                            </li>
                            <li>
                                <NavLink to='#App-development'>Solutions</NavLink>
                            </li>
                            <li>
                                <NavLink to='#App-development'>FAQ</NavLink>
                            </li>

                        </ul>
                    </div>
                    <div className='basis-1/4 mb-3 md:mb-0'>
                        <h4 className='text-blue-900 font-bold mb-4'>Contact</h4>
                        <p className='mb-2'>16 Kwara Street, Off Akowonjo Road, Egbeda, Lagos.</p>
                        <ul>
                            <li className='flex items-center gap-1'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="size-5 text-blue-900">
                                    <path
                                        fillRule="evenodd"
                                        d="M19.5 9.75a.75.75 0 0 1-.75.75h-4.5a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 1 1.5 0v2.69l4.72-4.72a.75.75 0 1 1 1.06 1.06L16.06 9h2.69a.75.75 0 0 1 .75.75Z"
                                        clipRule="evenodd"/>
                                    <path
                                        fillRule="evenodd"
                                        d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                                        clipRule="evenodd"/>
                                </svg>

                                +234-7030373137</li>
                                <li className='flex items-center gap-1'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="size-5 text-blue-900">
                                    <path
                                        fillRule="evenodd"
                                        d="M19.5 9.75a.75.75 0 0 1-.75.75h-4.5a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 1 1.5 0v2.69l4.72-4.72a.75.75 0 1 1 1.06 1.06L16.06 9h2.69a.75.75 0 0 1 .75.75Z"
                                        clipRule="evenodd"/>
                                    <path
                                        fillRule="evenodd"
                                        d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                                        clipRule="evenodd"/>
                                </svg>

                                +234-7012979979</li>
                            <li className='flex items-center gap-1'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="size-5 text-blue-900">
                                    <path
                                        d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z"/>
                                    <path
                                        d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z"/>
                                </svg>

                                info@brainbitsolutions.com</li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr className='bg-blue-900/10 my-[25px] h-[1px] border-0'/>
            <div className='max-w-6xl px-2 md:px-4 xl:px-8 mx-auto'>
                <div className='text-sm text-center'>&copy; 2024</div>
            </div>
        </div>
    )
}

export default Footer